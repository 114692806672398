html,body,#root{width: 100%; height:100%;}
body, div, ul, ol, dl, dt, dd, li, dl, h1, h2, h3, h4 {margin:0;padding:0;} 
ol, ul ,li{list-style: none;} 
img {border: 0; vertical-align:middle;} 
body{ font-size: 16px;} 
a{text-decoration:none; } 
a:hover{text-decoration:underline;} 
input:-webkit-autofill{
	box-shadow:0 0 0 1000px white inset !important;
}

/* @media screen and (min-width:2000px){
	body{
		font-size: 20px !important;
	}
}

@media screen and (min-width:3000px){
	body{
		font-size: 28px !important;
	}
} */

/*滚动条样式*/
::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: rgba(0,0,0,0.2);
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 10px;
    background: rgba(0,0,0,0.1);
}
:root{
    --themColor:#207554;
    --bodyBgColor:#f1f8f8;
}

.app_layout{
    width:100%;
    height:100%;
    background-color: var(--bodyBgColor);
    overflow: hidden;
}

.app_header{
    height: 50px !important;
    line-height: 50px !important;
    padding: 0 15px !important;
    background-color: var(--themColor) !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title{
        font-size: 24px;
        color: #fff;
        span{
            font-size: 18px;
            margin-left: 10px;
        }
    }
    .user{
        width: 130px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .ant-avatar{
            background: var(--bodyBgColor);
        }
        .user_info{
            display: flex;
            flex-direction: column;
            line-height: initial;
            span{
                color: #fff;
                font-size: 14px;
                line-height: inherit;
            }
        }
    }
}

.app_body{
    display: flex;
    height:calc(100% - 50px);
}

.app_menu{
    background-color: #fff;
    height: 100%;
    width: 100px;
    li{
        font-size: 18px;
        text-align: center;
        line-height: 3;
        margin-bottom: 15px;
        a{
            color: #333;
        }
    }
    .active{
        // background: #38c00012;
        border-right: 4px solid var(--themColor);
        a{
            color: var(--themColor);
        }
    }
}

.content_box{
    flex:1;
    height:100%;
    padding: 10px;
    overflow: auto;
    .action_wrap{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom:15px;
    }
    .table_action{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        span{
            cursor: pointer;
            margin-right: 10px;
            color: #37b27a;
        }
        .anticon{
            font-size: 18px;
        }
    }
}

.disable_url{
    color:#999;
}
.login_wrap{
    width:100%;
    height:100%;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.login_img{
    width:50%;
    height: 100%;
    border:0px solid transparent;
    border-top:100vh solid #00c8ac;
    border-right:180px solid transparent;
    img{
        width: 40vh;
        height: auto;
        position: absolute;
        top: 35%;
        left: 30%;
        transform: scale(1.4);
    }
}

.login_box{
    width:50%;
    z-index: 1;
    h1{
        margin-bottom: 50px;
        font-size: 28px;
        text-align: center;
        color: #00c8ac;
    }
    .ant-form{
        width:400px;
        margin:0 auto;
    }
    .ant-form-item-control-input-content{
        background: #f1f1f1;
        height:40px;
        .ant-input-affix-wrapper{
            height: 100%;
            background: transparent;
            .ant-input-prefix{
                border-right: 1px solid #ccc;
                padding-right: 10px;
            }
            .ant-input{
                background: transparent;
                padding-left:5px;
            }
        }
    }
    .ant-btn{
        height:40px;
    }
}

.footer{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    text-align: center;
    padding:5px 0;
    h3{
        color: #888;
        margin: 0;
        line-height: 1.2;
        img{
            width: 20px;
            height: 20px;
        }
    }
}
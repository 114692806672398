.spin{
    width: 100%;
    height: 100%;
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}